<template>
  <div>
     <div class="booking-details" v-if="event.travelersNow || event.travelersPotentiallyAffected">
       <div class="booking-details-travelers">
         <span class="booking-details-text">
           <img src="@/assets/group.png" class="logo" alt=""/>
           <span class="travelers-number">{{event.travelersNow}}</span> {{ $t('main.TRAVELERS_TODAY') }}
         </span>
         <span class="booking-details-text">
           <img src="@/assets/group.png" class="logo" alt=""/>
           <span class="travelers-number">{{event.travelersPotentiallyAffected}}</span> {{ $t('main.TRAVELERS_POTENTIALLY_AFFECTED') }}
         </span>
       </div>
        <v-btn
            class="booking-details-button"
            size="small"
            outlined
            min-width="110px"
            color="primary"
            @click="toggleDetails">
          {{ buttonText }}
        </v-btn>
     </div>
    <div v-if="showDetails" class="booking-details-table">
      <div class="affected_booking-header" id="affected_booking-header">
        <span style="text-align: center"> {{ $t('main.AFFECTED_BOOKING_TITLE') }} "{{ event.text }}"</span>
      </div>
      <div class="booking-table-container">
        <table class="booking-table" aria-describedby="affected_booking-header">
          <thead>
            <tr>
              <th> {{ $t('main.ORDER') }} </th>
              <th class="period-of-travel-sort" @click="sortType($event)"
                  :class="{ 'sorted-asc': currentSort === $t('main.PERIOD_OF_TRAVEL') && sortDirection === 'asc',
                   'sorted-desc': currentSort === $t('main.PERIOD_OF_TRAVEL') && sortDirection === 'desc' }">
                  {{ $t('main.PERIOD_OF_TRAVEL') }}
              </th>
              <th class="today-sort" @click="sortType($event)"
                  :class="{ 'sorted-asc': currentSort === $t('main.TODAY') && sortDirection === 'asc',
                   'sorted-desc': currentSort === $t('main.TODAY') && sortDirection === 'desc' }">
                  {{ $t('main.TODAY') }}
              </th>
              <th class="pax-sort" @click="sortType($event)"
                  :class="{ 'sorted-asc': currentSort === $t('main.PAX') && sortDirection === 'asc',
                   'sorted-desc': currentSort === $t('main.PAX') && sortDirection === 'desc' }">
                  {{ $t('main.PAX') }}
              </th>
              <th class="tour-operator-sort" @click="sortType($event)"
                  :class="{ 'sorted-asc': currentSort === $t('main.TOUROPERATOR') && sortDirection === 'asc',
                   'sorted-desc': currentSort === $t('main.TOUROPERATOR') && sortDirection === 'desc' }">
                  {{ $t('main.TOUROPERATOR') }}
              </th>
              <th> {{ $t('main.ACTION') }} </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(row, index) in items" :key="index">
              <td>{{ row.order }}</td>
              <td>{{ `${formatDate(row.travelStart)} - ${formatDate(row.travelEnd)}` }}</td>
              <td style="display: flex; justify-content: center; align-items: center; padding-top: 9px">
                <div style="width: 15px; height: 15px; background-color: #022f60;
                 border-radius: 50%;" v-if="row.today"></div>
              </td>
              <td>{{ row.pax }}</td>
              <td>{{ row.tourOperator }}</td>
              <td class="booking-button">
                <a :href="row.url"
                   target="_blank"
                   class="url-button"
                   role="button"
                >
                  {{ $t('main.OPEN_ACTION') }}
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'BosysDisplay',
  props: {
    event: {
      type: Object
    },
    bookingsEvent: {
      type: Array
    },
    showDetails: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      currentSort: this.$t('main.PERIOD_OF_TRAVEL'),
      sortDirection: 'asc'
    }
  },
  methods: {
    toggleDetails() {
      this.showDetails = !this.showDetails;
    },
    formatDate(dateString) {
      const [year, month, day] = dateString.split('-');
      return `${day}.${month}.${year}`;
    },
    sortType(event) {
      const column = event.target.textContent.trim();
      if (column === this.currentSort) {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        this.currentSort = column;
        this.sortDirection = 'asc';
      }
    },
    isToday(dateStart, dateEnd){
      const currentDate = new Date().setHours(0, 0, 0, 0);
      const date1 = new Date(dateStart).setHours(0, 0, 0, 0);
      const date2 = new Date(dateEnd).setHours(0, 0, 0, 0);

      return !!(currentDate >= date1 && currentDate <= date2);
    }
  },
  computed: {
    buttonText() {
      return this.showDetails ? this.$t('main.CLOSE_ACTION') : this.$t('main.SEE_DETAILS');
    },
    items() {
      const items = this.bookingsEvent.map(({ booking, url }) => ({
        order: booking.bosysTravelId,
        travelStart: booking.travelStart,
        travelEnd: booking.travelEnd,
        pax: booking.travelerCount,
        tourOperator: booking.touroperatorCode,
        url: url,
        today: this.isToday(booking.travelStart, booking.travelEnd)
      }));

      switch (this.currentSort) {
        case this.$t('main.PERIOD_OF_TRAVEL'):
          items.sort((a, b) => {
            const dateA = new Date(a.travelStart);
            const dateB = new Date(b.travelStart);
            return (this.sortDirection === 'asc') ? dateA - dateB : dateB - dateA;
          });
          break;
        case this.$t('main.PAX'):
          items.sort((a, b) => {
            return (this.sortDirection === 'asc') ? a.pax - b.pax : b.pax - a.pax;
          });
          break;
        case this.$t('main.TOUROPERATOR'):
          items.sort((a, b) => {
            const operatorA = a.tourOperator.toLowerCase();
            const operatorB = b.tourOperator.toLowerCase();
            return (this.sortDirection === 'asc') ? operatorA.localeCompare(operatorB) : operatorB.localeCompare(operatorA);
          });
          break;
        case this.$t('main.TODAY'):
          items.sort((a, b) => {
            return (this.sortDirection === 'asc') ? a.today - b.today: b.today - a.today;
          });
          break;
        default:
          items.sort((a, b) => new Date(a.travelStart) - new Date(b.travelStart));
          break;
      }

      return items;
    }
  },
}
</script>

<style scoped>

  .travelers-number{
    font-weight: 800;
  }

  .booking-details-travelers{
    width: 100%;
  }

  .booking-details-text{
    font-size: 14px;
    color: #0f0f0f;
    margin-left: 1%;
    margin-right: 3%;
  }

  .booking-details{
    margin-top: 1.5%;
    padding: 0.5%;
    background-color: #1976d21a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-left: 3px solid #022f60;
  }

  .booking-details-button{
    letter-spacing: normal;
    background-color: white;
    border: 2px solid;
    text-transform: none;
  }

  .affected_booking-header{
    background: #022f60;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2%;
    margin-bottom: 1%;
    margin-left: 2px;
    height: 50px;
    font-size: 16px;
  }


  .booking-table {
    width: 100%;
    border-collapse: collapse;
    padding-bottom: 2%;
  }

  .booking-table th, .booking-table td {
    padding-top: 1px;
    padding-bottom: 1px;
    text-align: left;
  }

  .booking-table th {
    position: sticky;
    top: 0;
    z-index: 2;
  }

  .booking-table tbody {
    display: block;
    max-height: 160px;
    overflow-y: auto;
  }

  .booking-table tbody::-webkit-scrollbar {
      width: 0;
  }

  .booking-table thead, .booking-table tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
  }

  .url-button:link, .url-button:visited{
    background-color: #022f60;
    color: white;
    padding: 5px 8px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    border-radius: 5px;
  }

  .url-button:hover, .url-button:active {
    background-color: #1976d21a;
    color: black;
  }

  .logo{
    width: 3%;
    margin-right: 10px;
    margin-bottom: -8px;
  }

  .period-of-travel-sort{
    cursor: pointer;
  }

  .pax-sort{
    cursor: pointer;
  }

  .tour-operator-sort{
    cursor: pointer;
  }

  .today-sort{
    cursor: pointer;
    text-align: center !important;
  }

  .sorted-asc::after {
    content: ' ▲';
  }

  .sorted-desc::after {
    content: ' ▼';
  }

</style>